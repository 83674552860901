import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import isEmpty from 'lodash/isEmpty';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import get from 'lodash/get';
import Typography from '../../../components/atoms/Typography';
import Container from '../../../components/molecules/Container';
import Alert from '../../../components/atoms/Alert';
import Button from '../../../components/atoms/Button';
import withErrorBoundary from '../../../utils/HOCs/withErrorBoundary';
import { COMPONENT_NAME } from './locators';
import styles from './style';
import { canUseDOM } from '../../../utils/canUseDOM';
import { CLOSE_ICON_TEXT } from '../../../../constants';
import { cookie } from '../../../utils/cookie';
import CustomLink from '../../../components/atoms/Link';
import {
  pushAnaylyticsData,
  createDynamicLabels,
} from '../../../../utils/analytics';
import { analyticsData } from '../../../../constants/Analytics/analytics-data';
import {
  getComponentName,
  getPageName,
} from '../../../utils/getPageComponentInfo';
import NoSsr from '@material-ui/core/NoSsr';
import { getTimeFromTimezone } from '../../../../utils/utility';
import clsx from 'clsx';
import moment from 'moment';

// #TODO: Needs to check export.
export const Notification = props => {
  let cookieValue = canUseDOM && Boolean(cookie.read('crisesNotification'));
  const pageName = getPageName(props);
  const [hideNotification, setHideNotification] = useState(cookieValue);
  const isItineraryMap = pageName === 'itinerary map';
  // const [messagefontStyle, setMessagefontStyle] = useState('body2');

  const expiryDate = {
    startDate: get(props, 'fields.startDate.value', ''),
    endDate: get(props, 'fields.expiryDate.value', ''),
  };
  const componentName = getComponentName(props);
  // set notificationBar to 1 to set header's position
  // 1 = 'absolute' else 'fixed'
  useEffect(() => {
    if (isActive && !hideNotification) {
      if (canUseDOM) {
        cookie.writeSessionCookie('notificationBar', 1);
        let headerAppBar = document.querySelector(
          '[role="banner"] > .MuiContainer-root .MuiContainer-root'
        );
        headerAppBar && headerAppBar.classList.add('page-has-notification-bar');
        // save noticiation height for other components use.
        setNotificationBarHeight();
        window.addEventListener('resize', setNotificationBarHeight, false);
      }
    }
  }, []);
  useEffect(() => {
    if (canUseDOM) {
      setHideNotification(Boolean(cookie.read('crisesNotification')));
    }
  }, [canUseDOM]);

  useEffect(() => {
    if (canUseDOM) {
      const crisisNotfValue = cookieValue;
      setHideNotification(crisisNotfValue);
    }
  }, [cookieValue]);

  useEffect(() => {
    setNotificationBarHeight();
  }, [hideNotification]);

  useEffect(() => {
    setNotificationBarHeight();
  });

  const setNotificationBarHeight = () => {
    if (canUseDOM) {
      setTimeout(() => {
        const notificationBarHeightElem = document.querySelector(
          '[role="banner"] .notificationWrapper'
        );
        const notificationBarHeight =
          (notificationBarHeightElem &&
            notificationBarHeightElem.clientHeight) ||
          0;
        document.documentElement.style.setProperty(
          '--notificationBar',
          `${notificationBarHeight}px`
        );
      }, 1000);
    }
  };

  const { fields } = props;
  if (isEmpty(fields)) {
    return '';
  }
  const notificationTypeVal =
    fields && fields.notificationType && fields.notificationType.value;

  if (
    expiryDate.startDate &&
    expiryDate.endDate &&
    (expiryDate.StartDate !== '0001-01-01T00:00:00Z' ||
      expiryDate.endDate !== '0001-01-01T00:00:00Z')
  ) {
    const currentTime = new Date();

    // case when no end date is provided
    // case when start date is after current days
    if (expiryDate.startDate && expiryDate.endDate === '0001-01-01T00:00:00Z') {
      expiryDate.endDate = getTimeFromTimezone(+4, 90); // for infinite tomorrow
    }
    // case when no start date is provided
    if (expiryDate.endDate && expiryDate.startDate === '0001-01-01T00:00:00Z') {
      expiryDate.startDate = getTimeFromTimezone(+4, -90); // for infinite past
    }
    let _startDate = new Date(expiryDate.startDate);
    let _endDate = new Date(expiryDate.endDate);
    if (
      !(
        currentTime.getTime() > _startDate.getTime() &&
        currentTime.getTime() < _endDate.getTime()
      )
    ) {
      return '';
    }
  }

  const addAnalyticsInfo = () => {
    const obj = {
      ...analyticsData.pageBodyInteraction.readMoreBtnClick,
      ...{
        label: createDynamicLabels([
          pageName,
          componentName,
          `${get(props, 'fields.heading.value', '')} - ${get(
            props,
            'fields.link.value.text',
            ''
          )}`,
        ]),
      },
    };
    pushAnaylyticsData(obj);
  };

  const classes = styles();
  const isActive = fields && fields.isActive && fields.isActive.value;
  const handleClose = (isReadMoreClick = false) => {
    if (notificationTypeVal == 'Favorite Cookies') {
      canUseDOM &&
        cookie.writeSessionCookie('favoriteCookiesNotification', true);
    } else {
      canUseDOM && cookie.writeSessionCookie('notificationBar', 0);
      canUseDOM && cookie.writeSessionCookie('crisesNotification', true);
      document.documentElement.style.setProperty('--notificationBar', '0px');
      if (isReadMoreClick) {
        addAnalyticsInfo(isReadMoreClick);
      }
    }
  };

  /*
  if hideNotification is true that means user already closed the notificaiton
  message. Hence no need to show it again
  */

  let messageFontStyle = 'body2';
  if (canUseDOM && notificationTypeVal == 'Favorite Cookies') {
    setHideNotification(Boolean(cookie.read('favoriteCookiesNotification')));
    messageFontStyle = 'body2Bold';
  }

  const fallbackUI = <div className="notificationWrapper"></div>;
  return (
    <NoSsr fallback={fallbackUI}>
      <div
        className={`${classes.notificationWrapper} ${
          isItineraryMap ? 'itineraryNotification' : ''
        } notificationWrapper`}
      >
        {isActive && !hideNotification && (
          <Container className={classes.root} data-locator={COMPONENT_NAME}>
            <Alert
              ariaLabel={(props.t && props.t('close')) || CLOSE_ICON_TEXT}
              handleCloseAlert={handleClose}
              className={clsx({
                ['desktopNotification']:
                  notificationTypeVal !== 'Favorite Cookies',
                ['infoIconTop']: notificationTypeVal === 'Favorite Cookies',
              })}
            >
              {fields.heading && (
                <Typography
                  variant="body2Bold"
                  component={'span'}
                  gutterBottom={false}
                >
                  <Text field={fields.heading} />{' '}
                </Typography>
              )}
              {
                <Typography
                  variant={messageFontStyle}
                  className={
                    (notificationTypeVal === 'Favorite Cookies' &&
                      classes.favNotificationText) ||
                    ''
                  }
                  component={'span'}
                  gutterBottom={false}
                >
                  <span
                    className={
                      notificationTypeVal === 'Favorite Cookies'
                        ? classes.favMessageWrap
                        : classes.messageWrap
                    }
                  >
                    {fields.message && <Text field={fields.message} />}
                  </span>
                  <Button
                    component="span"
                    buttonType="secondary"
                    hasBorder={false}
                    isStartIcon={true}
                    size="small"
                    field={fields.link}
                    from="notficationBanner"
                    onClick={() => handleClose(true)} // Sending here true which will identify in knowing is it readMore click or cross icon icon, usefull in analytics
                    className="ctaBtn"
                  />
                </Typography>
              }
            </Alert>
            {notificationTypeVal !== 'Favorite Cookies' && (
              <Alert
                ariaLabel={(props.t && props.t('close')) || CLOSE_ICON_TEXT}
                handleCloseAlert={handleClose}
                className="mobileNotification"
              >
                <CustomLink
                  className="ctaBtn"
                  field={fields.link}
                  onClick={() => handleClose(true)}
                >
                  <Typography
                    variant="body1"
                    component={'span'}
                    gutterBottom={false}
                    className={classes.message}
                  >
                    {fields &&
                      fields.mobileLink &&
                      fields.mobileLink.value &&
                      fields.mobileLink.value.text}
                  </Typography>
                </CustomLink>
              </Alert>
            )}
          </Container>
        )}
      </div>
    </NoSsr>
  );
};

export default withErrorBoundary(
  withSitecoreContext()(withNamespaces()(Notification))
);
